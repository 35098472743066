import React from 'react';
import Steps from '../components/Steps';
import CallToAction from '../components/CallToAction';
import Testimonials from '../components/Testimonials';
import CoachProfile from '../components/CoachProfile';

const Home = () => {
  return (
    <div>
      <section id="home"><Steps /></section>
      <section id="about"><CallToAction /></section>
      <section id="coach"><CoachProfile /></section>
      <section id="services"><Testimonials /></section>
    </div>
  );
};

export default Home;
