import React, { useState } from 'react';
import ScheduleCallForm from './ScheduleCallForm'; // Import the ScheduleCallModal

const CallToAction = () => {
  const [showScheduleCallModal, setShowScheduleCallModal] = useState(false);

  return (
    <section className="bg-white py-12 flex justify-center">
      <div className="bg-blue-700 text-white px-12 py-12 rounded-md shadow-md max-w-4xl text-center">
        <h2 className="text-3xl font-semibold">Ready to Unlock Your Potential? </h2>
        <p className="mt-4 text-lg">Do you want to discover how to boost your productivity and achieve your goals faster?
Book a Free Discovery Call Now!.</p>
<button onClick={() => setShowScheduleCallModal(true)} className="bg-blue-300 text-white px-4 py-2 rounded-md hover:bg-blue-900 transition duration-300 text-lg font-semibold">Schedule a Call</button>
</div>
 <ScheduleCallForm isOpen={showScheduleCallModal} onRequestClose={() => setShowScheduleCallModal(false)} />

    </section>

  );
};

export default CallToAction;
