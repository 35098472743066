import React from 'react';
import { useNavigate } from 'react-router-dom';
import coachImage from '../assets/images/coachImage.jpg'; // Update the path to your image

const CoachProfile = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/detailed-profile');
  };

  return (
    <section className="bg-white py-12">
      <div className="container mx-auto flex flex-wrap items-center px-6">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
        <img src={coachImage} alt="Business Coach" className="rounded-md shadow-md w-full max-w-lg mx-auto" />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-12">
          <h2 className="text-3xl font-semibold mb-4">Meet the Life Coach <span className="text-blue-800">Mohamed Mursal</span></h2>
          <p className="text-lg mb-6">Hello, I'm Mohamed Morsal, a life coach, author, and entrepreneur. I've written two books and have dedicated my career to helping people gain clarity on their goals, sustain them, and achieve work-life balance. As the founder of Murzali Platform, I run the Empowerment Edition newsletter and the Exclusive Journaling Club. With clients from over ten countries, I'm passionate about guiding individuals to reach their full potential.</p>
          <div className="flex space-x-8 mb-6">
            <div>
              <p className="text-4xl font-semibold">4+</p>
              <p className="text-gray-600">Experience</p>
            </div>
            <div>
              <p className="text-4xl font-semibold">2</p>
              <p className="text-gray-600">Author Two books</p>
            </div>
            <div>
              <p className="text-4xl font-semibold">100+</p>
              <p className="text-gray-600">Clients</p>
            </div>
          </div>
          <button
            onClick={handleLearnMoreClick}
            className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-900 transition duration-300"
          >
            Learn More About Me
          </button>
        </div>
      </div>
    </section>
  );
};

export default CoachProfile;
