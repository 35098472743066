import React from 'react';
import { Link } from 'react-router-dom';
import coachImage from '../assets/images/coachImage.jpg';

const HeroSection = ({ setShowScheduleCallModal }) => (
  <div className="container mx-auto mt-12 flex flex-wrap items-center px-6">
    <div className="w-full md:w-1/2">
      <h1 className="text-5xl font-bold text-blue-600">At Murzali Platform, I offer personalized life coaching</h1>
      <p className="mt-4 text-xl text-blue-600">to help you unlock your potential and achieve your goals. With 4 years of experience, I provide tailored coaching in career development, work-life balance, and productivity.</p>
      <div className="mt-6 flex space-x-2"> {/* Reduced space */}
        <Link to="/service" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-800 transition duration-300 text-lg font-semibold">
          View Services
        </Link>
        <button onClick={() => setShowScheduleCallModal(true)} className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-500 transition duration-300 text-lg font-semibold">
          Schedule a Call
        </button>
      </div>
      <div className="mt-6 flex items-center justify-center md:justify-start">
        <p className="ml-4 text-gray-300">Highly Rated Coach</p>
        <div className="ml-4 flex">
          <i className="fas fa-star text-yellow-500"></i>
          <i className="fas fa-star text-yellow-500"></i>
          <i className="fas fa-star text-yellow-500"></i>
          <i className="fas fa-star text-yellow-500"></i>
          <i className="fas fa-star text-yellow-500"></i>
        </div>
      </div>
    </div>
    <div className="w-full md:w-1/2 relative mt-8 md:mt-0">
      <img src={coachImage} alt="Business Coach" className="rounded-md shadow-md w-full max-w-lg mx-auto" />
      <div className="absolute top-4 right-4 bg-blue-600 text-white px-4 py-2 rounded-full text-lg font-semibold">
        <i className="fas fa-award"></i> 4+ Years Experience
      </div>
    </div>
  </div>
);

export default HeroSection;
