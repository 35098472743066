import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

const ScheduleCallModal = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [success, setSuccess] = useState(false); // Success state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem('token');
      console.log('Token:', token); // Log to verify the token
      await axios.post('https://murzaliplatform.onrender.com/api/calls', formData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLoading(false); // Stop loading
      setSuccess(true); // Set success state to true
      setFormData({
        title: '',
        description: '',
        date: '',
        time: '',
      });
    } catch (err) {
      setError('Scheduling call failed. Please try again.');
      setLoading(false); // Stop loading
      setSuccess(false);
      console.error('Error scheduling call:', err.response ? err.response.data : err.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Schedule a Call</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {loading ? (
          <p className="text-center text-blue-600">Scheduling...</p> // Display loading indicator
        ) : success ? (
          <div className="text-center">
            <p className="text-green-600 text-xl font-bold">Call scheduled successfully!</p> {/* Success message */}
            <button onClick={onRequestClose} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 text-lg font-semibold">Close</button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">Title</label>
              <input type="text" name="title" value={formData.title} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">Description</label>
              <textarea name="description" value={formData.description} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">Date</label>
              <input type="date" name="date" value={formData.date} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">Time</label>
              <input type="time" name="time" value={formData.time} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 text-lg font-semibold">Schedule</button>
          </form>
        )}
        {!loading && !success && (
          <button onClick={onRequestClose} className="mt-4 text-blue-500 hover:text-blue-700">Close</button>
        )}
      </div>
    </Modal>
  );
};

export default ScheduleCallModal;
