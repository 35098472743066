import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://murzaliplatform.onrender.com/api/blogs');
        setBlogs(res.data);
      } catch (err) {
        setError('Failed to fetch blogs. Please try again.');
      }
    };

    fetchBlogs();
  }, []);

  if (error) return <p>{error}</p>;

  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-center mb-12">Latest Blog Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map(blog => (
            <div key={blog._id} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              {blog.coverImage && (
                <img src={`https://murzaliplatform.onrender.com/uploads/${blog.coverImage}`} alt={blog.title} className="w-full h-48 object-cover rounded-md mb-4" />
              )}
              <h3 className="text-2xl font-bold mb-2 hover:underline">
                <Link to={`/blog/${blog._id}`}>{blog.title}</Link>
              </h3>
              <p className="text-gray-700 mb-4">{blog.description}</p>
              <div className="flex items-center">
                <div>
                  <p className="text-gray-800 font-semibold">{blog.author?.name}</p>
                  <p className="text-gray-500 text-sm flex items-center">
                    <time dateTime={blog.date}>{new Date(blog.createdAt).toDateString()}</time>
                    {blog.readTime && (
                      <>
                        <span className="mx-2 w-1 h-1 bg-gray-400 rounded-full"></span>
                        <ion-icon name="time-outline" className="mr-1"></ion-icon>
                        <time dateTime={`PT${blog.readTime.replace(' min', '')}M`}>{blog.readTime}</time>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="mt-12 mx-auto bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">Load More</button>
      </div>
    </div>
  );
};

export default BlogSection;
