import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MySchedule = () => {
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in again.');
          return;
        }

        const res = await axios.get('https://murzaliplatform.onrender.com/api/calls', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSchedules(res.data);
      } catch (err) {
        setError('Failed to fetch schedules. Please try again.');
        console.error('Error fetching schedules:', err.response ? err.response.data : err.message);
      }
    };

    fetchSchedules();
  }, []);

  const handleCheckboxChange = (id) => {
    if (selectedSchedules.includes(id)) {
      setSelectedSchedules(selectedSchedules.filter(scheduleId => scheduleId !== id));
    } else {
      setSelectedSchedules([...selectedSchedules, id]);
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await Promise.all(selectedSchedules.map(id =>
        axios.delete(`https://murzaliplatform.onrender.com/api/calls/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      ));
      setSchedules(schedules.filter(schedule => !selectedSchedules.includes(schedule._id)));
      setSelectedSchedules([]);
    } catch (err) {
      setError('Failed to delete schedules. Please try again.');
      console.error('Error deleting schedules:', err.response ? err.response.data : err.message);
    }
  };

  return (
    <div className="p-5 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-center mb-5">My Schedule</h2>
      {error && <p className="text-red-500 text-center mb-5">{error}</p>}
      <table className="min-w-full bg-white">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="w-1/12 py-2"></th>
            <th className="w-3/12 py-2">Title</th>
            <th className="w-3/12 py-2">Description</th>
            <th className="w-2/12 py-2">Date</th>
            <th className="w-2/12 py-2">Time</th>
            <th className="w-2/12 py-2">User</th>
          </tr>
        </thead>
        <tbody>
          {schedules.map(schedule => (
            <tr key={schedule._id} className="border-t">
              <td className="py-2 px-4">
                <input
                  type="checkbox"
                  checked={selectedSchedules.includes(schedule._id)}
                  onChange={() => handleCheckboxChange(schedule._id)}
                />
              </td>
              <td className="py-2 px-4">{schedule.title}</td>
              <td className="py-2 px-4">{schedule.description}</td>
              <td className="py-2 px-4">{new Date(schedule.date).toLocaleDateString()}</td>
              <td className="py-2 px-4">{schedule.time}</td>
              <td className="py-2 px-4">{schedule.user?.name || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={handleDelete}
        className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-md hover:bg-red-600 transition duration-300 text-lg font-semibold"
      >
        Delete Selected
      </button>
    </div>
  );
};

export default MySchedule;
