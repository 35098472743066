import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://murzaliplatform.onrender.com/api/blogs');
        setBlogs(res.data);
      } catch (err) {
        setError('Failed to fetch blogs. Please try again.');
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <h1 className="text-3xl font-bold mb-6">Blogs</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map(blog => (
          <div key={blog._id} className="bg-white p-6 rounded-lg shadow-md">
            <img src={`/uploads/${blog.coverImage}`} alt={blog.title} className="w-full h-40 object-cover mb-4 rounded" />
            <h2 className="text-xl font-semibold mb-2"><Link to={`/blog/${blog._id}`}>{blog.title}</Link></h2>
            <p className="text-gray-700 mb-4">{blog.content.slice(0, 100)}...</p>
            <Link to={`/blog/${blog._id}`} className="text-blue-500 hover:text-blue-700">Read more</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
