import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faBalanceScale, faBookOpen, faCheckCircle, faEnvelopeOpenText, faChartLine } from '@fortawesome/free-solid-svg-icons';

const steps = [
  {
    id: 1,
    title: 'One-on-One Coaching',
    description: 'Experience personalized guidance tailored to your unique goals and journey. Together, we will clarify your aspirations, assess where you stand, and map out a clear path forward towards your desired outcomes',
    icon: faChalkboardTeacher,
  },
  {
    id: 2,
    title: 'Work-Life Balance',
    description: 'Discover strategies to harmonize productivity with personal well-being. Our program emphasizes holistic approaches to manage priorities effectively, ensuring fulfillment both in career and personal life',
    icon: faBalanceScale,
  },
  {
    id: 3,
    title: 'Exclusive Journaling Club',
    description: ' Engage in a transformative practice of self-reflection and growth. Through structured prompts and community support, elevate your clarity, creativity, and mindfulness in a nurturing environment',
    icon: faBookOpen,
  },
  {
    id: 4,
    title: 'Accountability Coaching',
    description: 'Join our intensive three-month challenge designed to cultivate discipline and focus. Through structured milestones and consistent support, you will achieve sustainable progress and surpass your personal and professional goals',
    icon: faCheckCircle,
  },
  {
    id: 5,
    title: 'Empowerment Edition',
    description: 'Dive into actionable insights and expert tips curated to enhance your productivity and personal growth journey. Join a vibrant community committed to continuous improvement and achieving your fullest potential',
    icon: faEnvelopeOpenText,
  },
  {
    id: 6,
    title: 'Weekly Workshops',
    description: 'We offer weekly workshops covering a range of topics, including productivity, work-life balance, and personal growth. Our sessions are designed to help you enhance your skills and achieve your full potential.',
    icon: faChartLine,
  }
];

const StepsSection = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Our Coaching Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {steps.map(step => (
            <div key={step.id} className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
              <div className="flex justify-center mb-4">
                <FontAwesomeIcon icon={step.icon} className="text-4xl text-blue-600"/>
              </div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-700">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
