import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo-removebg-preview.png';
import SignInModal from './LoginPage';
import ScheduleCallForm from './ScheduleCallForm';

const Header = () => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showScheduleCallModal, setShowScheduleCallModal] = useState(false);
  const [user, setUser] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const handleLogin = (user) => {
    setUser(user);
    setShowSignInModal(false);
    if (user.role === 'admin') {
      navigate('/dashboard'); 
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="bg-blue-100 py-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center px-4">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        
        <div className="flex-grow hidden md:flex justify-center">
          <nav className="space-x-8">
            <Link to="/" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Home</Link>
            <Link to="/about" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">About</Link>
            <Link to="/blog" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Blog</Link>
            <Link to="/service" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Services</Link>
            <Link to="/contact" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Contact</Link>
          </nav>
        </div>

        <div className="hidden md:flex items-center space-x-4">
          {user && <span className="text-blue-600">Welcome, {user.name}</span>}
          <a
            href="https://api.whatsapp.com/send?phone=252614035267&text=Hello%20I%20need%20help"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300 text-lg font-semibold"
          >
            WhatsApp
          </a>
          {user ? (
            <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 text-lg font-semibold">
              Logout
            </button>
          ) : (
            <button onClick={() => setShowSignInModal(true)} className="bg-blue-700 text-white px-4 py-2 rounded-md hover:bg-blue-800 transition duration-300 text-lg font-semibold">
              Sign In
            </button>
          )}
        </div>

        <button onClick={toggleNav} className="text-blue-600 md:hidden">
          {isNavOpen ? (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          ) : (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-8 6h8"></path>
            </svg>
          )}
        </button>

        <nav
          className={`fixed top-0 right-0 h-full bg-white p-8 shadow-lg transform ${isNavOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:hidden`}
        >
          <button onClick={toggleNav} className="absolute top-4 right-4 text-2xl text-blue-600">
            &times;
          </button>
          <div className="flex flex-col items-center space-y-4 mt-8">
            <Link to="/" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Home</Link>
            <Link to="/about" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">About</Link>
            <Link to="/blog" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Blog</Link>
            <Link to="/service" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Services</Link>
            <Link to="/contact" className="text-blue-600 hover:text-gray-400 text-lg font-semibold">Contact</Link>
            
            {user && <span className="text-blue-600">Welcome, {user.name}</span>}
            <a
              href="https://api.whatsapp.com/send?phone=252614035267&text=Hello%20I%20need%20help"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300 text-lg font-semibold"
            >
              WhatsApp
            </a>
            {user ? (
              <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 text-lg font-semibold">
                Logout
              </button>
            ) : (
              <button onClick={() => setShowSignInModal(true)} className="bg-blue-700 text-white px-4 py-2 rounded-md hover:bg-blue-800 transition duration-300 text-lg font-semibold">
                Sign In
              </button>
            )}
          </div>
        </nav>
      </div>

      <SignInModal isOpen={showSignInModal} onRequestClose={() => setShowSignInModal(false)} onLogin={handleLogin} />
      <ScheduleCallForm isOpen={showScheduleCallModal} onRequestClose={() => setShowScheduleCallModal(false)} />
    </header>
  );
};

export default Header;
