import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await axios.get(`https://murzaliplatform.onrender.com/api/blogs/${id}`);
        setBlog(res.data);
      } catch (err) {
        console.error('Error fetching blog:', err.response ? err.response.data : err.message);
        setError('Failed to fetch blog. Please try again.');
      }
    };

    fetchBlog();
  }, [id]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://murzaliplatform.onrender.com/api/blogs');
        setBlogs(res.data);
      } catch (err) {
        console.error('Error fetching blogs:', err.response ? err.response.data : err.message);
        setError('Failed to fetch blogs. Please try again.');
      }
    };

    fetchBlogs();
  }, []);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const res = await axios.post(`https://murzaliplatform.onrender.com/api/blogs/${id}/comments`, { text: comment }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSuccess('Comment added successfully');
      setError('');
      setComment('');
      setBlog({ ...blog, comments: [...blog.comments, res.data] });

      // Call the incrementReadCount endpoint
      await axios.put(`https://murzaliplatform.onrender.com/api/blogs/incrementReadCount/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
    } catch (err) {
      console.error('Error adding comment:', err.response ? err.response.data : err.message);
      setError('Failed to add comment. Please try again.');
      setSuccess('');
    }
  };

  if (!blog) return <p>Loading...</p>;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl">
        {blog.coverImage && (
          <img src={`https://murzaliplatform.onrender.com/uploads/${blog.coverImage}`} alt={blog.title} className="w-full h-80 object-cover mb-4 rounded" />
        )}
        <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>

        {blog.blogFile ? (
          <a href={`https://murzaliplatform.onrender.com/uploads/${blog.blogFile}`} download className="text-blue-500 hover:text-blue-700">
            Download the blog as a file
          </a>
        ) : (
          <p className="text-gray-700 mb-6">{blog.content}</p>
        )}

        <h2 className="text-2xl font-semibold mb-4">Comments</h2>
        {blog.comments && blog.comments.length > 0 ? (
          blog.comments.map(comment => (
            <div key={comment._id} className="mb-4">
              <p className="text-gray-900">
                <strong>{comment.user ? comment.user.name : 'Unknown'}:</strong> {comment.text}
              </p>
              <p className="text-gray-500 text-sm">{new Date(comment.createdAt).toLocaleString()}</p>
            </div>
          ))
        ) : (
          <p>No comments yet.</p>
        )}
        <form onSubmit={handleCommentSubmit} className="mt-6">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {success && <p className="text-green-500 mb-4">{success}</p>}
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded mb-4"
            rows="3"
            placeholder="Add a comment..."
            required
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Add Comment
          </button>
        </form>
        <Link to="/blog" className="mt-4 text-blue-500 hover:text-blue-700">Back to Blogs</Link>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mt-8">
        <h2 className="text-2xl font-semibold mb-4">Previous Blogs</h2>
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <Link to={`/blog/${blog._id}`} key={blog._id} className="block mb-4">
              <h3 className="text-xl text-blue-600">{blog.title}</h3>
            </Link>
          ))
        ) : (
          <p>No blogs found.</p>
        )}
      </div>
    </div>
  );
};

export default BlogDetail;
