import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import tm1 from '../assets/images/tstm2.jpg';
import tm2 from '../assets/images/tstm1.jpg';
import tm3 from '../assets/images/tstm3.jpg';

const Testimonials = () => {
  // Slick settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className="bg-gray-200 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold">Explore the Testimonials From Our Clients</h2>
        
        {/* Carousel for Mobile View */}
        <div className="block md:hidden mt-8"> {/* Show only on small screens */}
          <Slider {...settings}>
            <div className="bg-white p-6 rounded-md shadow-md relative">
              <img src={tm1} alt="Jenny Wilson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
              <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
              <p>Thanks to Mohamed’s coaching, I’ve been able to maintain a work-life balance while still reaching my career goals. His strategies are practical and easy to implement.</p>
              <p className="mt-4 font-semibold">- Dr. Idil Mohamed, Founder of Kafia Center</p>
              <div className="mt-2 flex justify-center">
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
              </div>
            </div>
            <div className="bg-white p-6 rounded-md shadow-md relative">
              <img src={tm2} alt="Danny Thompson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
              <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
              <p>Murzali Platform provided the clarity and motivation I needed. Mohamed's approach is empathetic and effective. I feel more in control of my goals and my time.</p>
              <p className="mt-4 font-semibold">- Abdisalam, Clarity Coaching Alumni</p>
              <div className="mt-2 flex justify-center">
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
              </div>
            </div>
            <div className="bg-white p-6 rounded-md shadow-md relative">
              <img src={tm3} alt="Kristin Watson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
              <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
              <p>Mohamed’s coaching has been a game-changer for me. His insights and techniques helped me overcome procrastination and achieve a level of productivity I never thought possible.</p>
              <p className="mt-4 font-semibold">- Abdirizak Muktar, Accountability Coaching</p>
              <div className="mt-2 flex justify-center">
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
                <i className="fas fa-star text-yellow-500"></i>
              </div>
            </div>
          </Slider>
        </div>

        {/* Static layout for Desktop View */}
        <div className="hidden md:flex justify-around mt-8 space-x-4"> {/* Show only on medium/large screens */}
          <div className="w-1/3 bg-white p-6 rounded-md shadow-md relative">
            <img src={tm1} alt="Jenny Wilson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
            <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
            <p>Thanks to Mohamed’s coaching, I’ve been able to maintain a work-life balance while still reaching my career goals. His strategies are practical and easy to implement.</p>
            <p className="mt-4 font-semibold">- Dr. Idil Mohamed, Founder of Kafia Center</p>
            <div className="mt-2 flex justify-center">
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
            </div>
          </div>
          <div className="w-1/3 bg-white p-6 rounded-md shadow-md relative">
            <img src={tm2} alt="Danny Thompson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
            <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
            <p>Murzali Platform provided the clarity and motivation I needed. Mohamed's approach is empathetic and effective. I feel more in control of my goals and my time.</p>
            <p className="mt-4 font-semibold">- Abdisalam, Clarity Coaching Alumni</p>
            <div className="mt-2 flex justify-center">
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
            </div>
          </div>
          <div className="w-1/3 bg-white p-6 rounded-md shadow-md relative">
            <img src={tm3} alt="Kristin Watson" className="w-16 h-16 rounded-full mx-auto mb-4"/>
            <i className="fas fa-quote-left text-gray-300 absolute top-4 left-4 text-4xl"></i>
            <p>Mohamed’s coaching has been a game-changer for me. His insights and techniques helped me overcome procrastination and achieve a level of productivity I never thought possible.</p>
            <p className="mt-4 font-semibold">- Abdirizak Muktar, Accountability Coaching</p>
            <div className="mt-2 flex justify-center">
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
              <i className="fas fa-star text-yellow-500"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
