import React from 'react';
import video1 from '../assets/images/ab.mp4';
import video2 from '../assets/images/󱡓󰥇1...15...(720p).mp4';
import video3 from '../assets/images/Facebook_1722684576931.mp4';

const Services = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="container mx-auto p-8">
        {/* Main Title */}
        <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Our Services</h1>
        
        {/* Flex Videos Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* First Video Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-700">Accountability Coaching</h2>
            <video className="w-full h-56 mb-4 rounded-lg" controls>
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-gray-700 text-center">
              Accountability coaching is a supportive partnership where I help you set clear goals, create actionable plans, and stay committed to achieving them. Through regular check-ins and personalized guidance, I hold you accountable, providing the motivation and feedback you need to overcome challenges and stay on track.
            </p>
          </div>

          {/* Second Video Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-700">One-To-one Coaching</h2>
            <video className="w-full h-56 mb-4 rounded-lg" controls>
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-gray-700 text-center">
              One-to-one coaching offers a personalized approach to your growth and development. In these sessions, we focus exclusively on your unique goals, challenges, and aspirations. Through tailored guidance and support, I help you explore your strengths, overcome obstacles, and create actionable plans for success.
            </p>
          </div>

          {/* Third Video Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-700">Work-Life Balance</h2>
            <video className="w-full h-56 mb-4 rounded-lg" controls>
              <source src={video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="text-gray-700 text-center">
              Work-life balance is about creating a harmonious relationship between your personal and professional life. It involves managing your time and energy effectively so that you can fulfill your work responsibilities while still having time for yourself, your family, and the activities that bring you joy.
            </p>
          </div>
        </div>

                
        
      </div>
    </div>
  );
};

export default Services;

       
