import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-removebg-preview.png';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto flex flex-wrap justify-between px-6">
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <img src={logo} alt="Logo" className="mb-4 w-16 h-16"/>
          <p>Elevate your business with our coaching services <br></br> and take your business to the next level. <br></br>Grow your business today and become a business leader with our expert coaching services.</p>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Get to Know Us</h3>
          <ul>
            <li className="mb-2"><Link to="/" className="text-gray-400 hover:text-gray-200">Home</Link></li>
            <li className="mb-2"><Link to="/about" className="text-gray-400 hover:text-gray-200">About Us</Link></li>
            <li className="mb-2"><Link to="/blog" className="text-gray-400 hover:text-gray-200">Blog</Link></li>
            <li className="mb-2"><Link to="/service" className="text-gray-400 hover:text-gray-200">Services</Link></li>
            <li className="mb-2"><Link to="/contact" className="text-gray-400 hover:text-gray-200">Contact Us</Link></li>

          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Our Services</h3>
          <ul>
            <li className="mb-2"><Link to="/services#business-coaching" className="text-gray-400 hover:text-gray-200">Accountability Coaching</Link></li>
            <li className="mb-2"><Link to="/services#executive-coaching" className="text-gray-400 hover:text-gray-200">One-on-one Coaching Session</Link></li>
            <li className="mb-2"><Link to="/services#leadership-coaching" className="text-gray-400 hover:text-gray-200">Work Life Balance</Link></li>
            <li className="mb-2"><Link to="/services#accountability-coaching" className="text-gray-400 hover:text-gray-200">Exclusive Journaling Club</Link></li>
            <li className="mb-2"><Link to="/services#strategic-planning" className="text-gray-400 hover:text-gray-200">Empowerment Newsletter Edition</Link></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Social Links</h3>
          <ul>
            <li className="mb-2"><a href="https://www.facebook.com/profile.php?id=100070033380738&mibextid=ZbWKwL" className="text-gray-400 hover:text-gray-200">Facebook</a></li>
            <li className="mb-2"><a href="https://twitter.com" className="text-gray-400 hover:text-gray-200">Twitter</a></li>
            <li className="mb-2"><a href="https://www.instagram.com/mohamedmurzali?igsh=MXg0bm04ODB5ZG53bA==" className="text-gray-400 hover:text-gray-200">Instagram</a></li>
            <li className="mb-2"><a href="https://youtube.com/@mohamedmurzali?si=hBjPRw-yBu-Wvwon" className="text-gray-400 hover:text-gray-200">YouTube</a></li>
            <li className="mb-2"><a href="https://www.tiktok.com/@murzaliplatform?_t=8o6VlyQVs3B&_r=1" className="text-gray-400 hover:text-gray-200">TikTok</a></li>
            <li className="mb-2"><a href="https://www.linkedin.com/in/mohamed-murzali-54182b314?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="text-gray-400 hover:text-gray-200">LinkedIn</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
  <h3 className="text-xl font-semibold mb-4">Subscribe to Our Newsletter</h3>
  <form 
    onSubmit={async (e) => {
      e.preventDefault();
      const email = e.target.email.value;
      try {
        await fetch('http://localhost:3000/api/subscribe', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        alert('Subscribed successfully!');
        e.target.reset();
      } catch (err) {
        alert('Error subscribing. Please try again.');
      }
    }} 
    className="flex flex-col gap-4"
  >
    <input 
      type="email" 
      name="email" 
      placeholder="Your Email Address" 
      required 
      className="p-3 border border-gray-300 rounded bg-gray-800 text-white"
    />
    <button 
      type="submit" 
      className="bg-purple-600 text-white py-3 px-6 rounded hover:bg-purple-700 transition duration-300"
    >
      Subscribe
    </button>
  </form>
</div>

      </div>
      <div className="container mx-auto text-center mt-8">
        <p className="text-gray-600">&copy; 2024 Murzali Platform. All Rights Reserved.</p>
        <div className="mt-4">
          <a href="#terms" className="text-gray-400 hover:text-gray-200 mx-2">Terms and Conditions</a>
          <a href="#privacy" className="text-gray-400 hover:text-gray-200 mx-2">Privacy Policy</a>
          <a href="#cookies" className="text-gray-400 hover:text-gray-200 mx-2">Cookie Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
