import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import AboutUs from '../pages/AboutUs';
import Service from '../pages/Services';
import Header from './Header';
import Footer from './Footer';
import Contact from '../pages/ContactUs';
import BlogList from './BlogList';
import BlogDetail from './BlogDetail';
import Dashboard from '../pages/Dashboard';
import UploadBlog from './UploadBlog';
import MyUsers from './MyUsers';
import MyBlogs from './MyBlogs';
import MySchedule from './MyscheduleCalls';
import AdminLayout from './AdminLayout';
import ScheduleCallForm from './ScheduleCallForm';
import StepsSection from './Steps';
import DetailedProfile from './DetailedProfile';
import BlogSection from './BlogSection';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute'; 
import UpdateBlog from './UpdateBlog';
import HeroSection from './heroSection';

const AppContent = () => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setRole(user.role);
    }
  }, []);

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/"
        element={
          <>
            <Header />
            <HeroSection/>
            <Home />
            <Footer />
          </>
        }
      />
      <Route
        path="/blogSection"
        element={
          <>
            <BlogSection />
            <Footer />
          </>
        }
      />
      <Route
        path="/blog/:id"
        element={
          <>
            <BlogDetail />
            <Footer />
          </>
        }
      />
      <Route
        path="/login"
        element={
          <>
            <Header />
            <LoginPage />
            <Footer />
          </>
        }
      />
       
      <Route
        path="/blog"
        element={
          <>
            <Header />
            <Blog />
            <Footer />
        </>
        }
      />
       
       <Route
        path="/service"
        element={
          <> 
            <Header />
            <Service />
            <Footer />
          </>
        }
      />

      <Route
        path="/about"
        element={
          <> 
            <Header />
            <AboutUs />
            <Footer />
          </>
        }
      />
      <Route
        path="/contact"
        element={
          <>
            <Header />
            <Contact />
            <Footer />
          </>
        }
      />
      <Route
        path="/posts"
        element={
          <>
            <BlogList />
            <Footer />
          </>
        }
      />
      <Route
        path="/posts/:id"
        element={
          <>
            <BlogDetail />
            <Footer />
          </>
        }
      />
      <Route
        path="/schedule-call"
        element={
          <>
            <Header />
            <ScheduleCallForm />
            <Footer />
          </>
        }
      />
      <Route
        path="/service"
        element={
          <>
            <Header />
            <StepsSection />
            <Footer />
          </>
        }
      />
      <Route
        path="/detailed-profile"
        element={
          <>
            <DetailedProfile />
            <Footer />
          </>
        }
      />

      {/* Admin Routes */}
      {role === 'admin' ? (
        <>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute role="admin">
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-users"
            element={
              <ProtectedRoute role="admin">
                <AdminLayout>
                  <MyUsers />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
         
          <Route
            path="/my-schedule"
            element={
              <ProtectedRoute role="admin">
                <AdminLayout>
                  <MySchedule />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-blogs"
            element={
              <ProtectedRoute role="admin">
                <AdminLayout>
                  <MyBlogs />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route path="/update-blog/:id" element={<ProtectedRoute role="admin">
                  <UpdateBlog />
              </ProtectedRoute>} />

          <Route
            path="/upload-blog"
            element={
              <ProtectedRoute role="admin">
                <AdminLayout>
                  <UploadBlog />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
        </>
      ) : (
        <Route path="/dashboard" element={<Navigate to="/" />} />
      )}
    </Routes>
  );
};

export default AppContent;
