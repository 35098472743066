import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UploadBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [blogFile, setBlogFile] = useState(null);
  const [uploadType, setUploadType] = useState('content');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.name === 'coverImage') {
      setCoverImage(e.target.files[0]);
    } else if (e.target.name === 'blogFile') {
      setBlogFile(e.target.files[0]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    if (uploadType === 'content') {
      formData.append('content', content);
    } else {
      formData.append('blogFile', blogFile);
    }
    formData.append('coverImage', coverImage);

    try {
      const token = localStorage.getItem('token');
      await axios.post('https://murzaliplatform.onrender.com/api/blogs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      navigate('/my-blogs');
    } catch (err) {
      setError('Failed to upload blog. Please try again.');
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-3xl font-bold mb-4">Upload Blog</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleUpload} encType="multipart/form-data">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
              Cover Image
            </label>
            <input
              type="file"
              id="coverImage"
              name="coverImage"
              onChange={handleFileChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Upload Type</label>
            <div className="flex items-center">
              <input
                type="radio"
                id="content"
                name="uploadType"
                value="content"
                checked={uploadType === 'content'}
                onChange={() => setUploadType('content')}
              />
            </div>
          </div>
          {uploadType === 'content' ? (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
                Content
              </label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                rows="6"
              />
            </div>
          ) : (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="blogFile">
                Blog File
              </label>
              <input
                type="file"
                id="blogFile"
                name="blogFile"
                onChange={handleFileChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Upload
          </button>
        </form>
      </div>
    </div>
  );
};

export default UploadBlog;
