import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBlog, FaTachometerAlt, FaUsers, FaCalendarAlt, FaUpload, FaSignOutAlt } from 'react-icons/fa';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <div className="sidebar w-64 h-screen bg-gray-800 text-white p-6 flex flex-col justify-between">
      <div>
        <h2 className="text-2xl font-bold mb-8">Murzali Platform</h2>
        <ul>
          <li className="mb-6 flex items-center">
            <FaTachometerAlt className="mr-3" />
            <Link to="/dashboard" className="text-white hover:text-gray-400">Dashboard</Link>
          </li>
          <li className="mb-6 flex items-center">
            <FaUsers className="mr-3" />
            <Link to="/my-users" className="text-white hover:text-gray-400">My Users</Link>
          </li>
          <li className="mb-6 flex items-center">
            <FaCalendarAlt className="mr-3" />
            <Link to="/my-schedule" className="text-white hover:text-gray-400">My Schedule</Link>
          </li>
          <li className="mb-6 flex items-center">
            <FaBlog className="mr-3" />
            <Link to="/my-blogs" className="text-white hover:text-gray-400">My Blog</Link>
          </li>
          <li className="mb-6 flex items-center">
            <FaUpload className="mr-3" />
            <Link to="/upload-blog" className="text-white hover:text-gray-400">Upload Blog</Link>
          </li>
          <li className="mb-6 flex items-center">
            <FaUpload className="mr-3" />
            <Link to="/send-newsletter" className="text-white hover:text-gray-400">Send Newsletter</Link>
          </li>
        </ul>
      </div>
      <div>
        <button 
          onClick={handleLogout}
          className="w-full flex items-center justify-center bg-blue-600 text-white px-4 py-3 rounded hover:bg-red-700 transition duration-300"
        >
          <FaSignOutAlt className="mr-3" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
