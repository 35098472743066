import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Dashboard = () => {
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalReaders, setTotalReaders] = useState(0);
  const [totalSchedules, setTotalSchedules] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [blogStatistics, setBlogStatistics] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');

        const statsResponse = await axios.get('https://murzaliplatform.onrender.com/api/dashboard/stats', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setTotalBlogs(statsResponse.data.totalBlogs);
        setTotalReaders(statsResponse.data.totalReaders);
        setTotalSchedules(statsResponse.data.totalSchedules);
        setTotalUsers(statsResponse.data.totalUsers);

        const blogsResponse = await axios.get('https://murzaliplatform.onrender.com/api/blogs/statistics', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBlogStatistics(blogsResponse.data);

      } catch (err) {
        console.error('Error fetching dashboard data:', err);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-4 text-center md:text-left">Welcome back, Mohamed Mursal!</h1>
      <p className="text-lg mb-8 text-center md:text-left">Here's your overview today</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h2 className="text-xl font-bold">Total Blogs</h2>
          <p className="text-2xl">{totalBlogs}</p>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h2 className="text-xl font-bold">Total Readers</h2>
          <p className="text-2xl">{totalReaders}</p>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h2 className="text-xl font-bold">Total Schedules</h2>
          <p className="text-2xl">{totalSchedules}</p>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h2 className="text-xl font-bold">Total Users</h2>
          <p className="text-2xl">{totalUsers}</p>
        </div>
      </div>
      <div className="bg-white p-6 shadow-md rounded-lg overflow-x-auto">
        <h2 className="text-2xl font-bold mb-4">Blogs Statistics</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">Rank</th>
              <th className="py-2 px-4 border-b text-left">Blog Title</th>
              <th className="py-2 px-4 border-b text-left">Readers</th>
              <th className="py-2 px-4 border-b text-left">Comments</th>
            </tr>
          </thead>
          <tbody>
            {blogStatistics.map((blog, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b">{index + 1}</td>
                <td className="py-2 px-4 border-b">{blog.title}</td>
                <td className="py-2 px-4 border-b">{blog.readers}</td>
                <td className="py-2 px-4 border-b">{blog.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
