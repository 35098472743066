import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faShieldAlt, faChartLine, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import companyImg from '../assets/images/withTheCoach.jpeg';
import myVideo from '../assets/images/ab.mp4';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        
        {/* Core Values Section */}
        <section className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Our Core Values</h2>
          <p className="text-gray-600 mb-12">Our values shape the culture of our organization and define the character of our company.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-4">
              <FontAwesomeIcon icon={faHandsHelping} className="text-blue-600 text-4xl mx-auto mb-4"/>
              <h3 className="text-xl font-semibold mb-2">Accountability</h3>
              <p className="text-gray-600">Promoting responsibility and accountability to ensure goals are met and promises are kept.
              </p>
            </div>
            <div className="p-4">
              <FontAwesomeIcon icon={faShieldAlt} className="text-blue-600 text-4xl mx-auto mb-4"/>
              <h3 className="text-xl font-semibold mb-2">Empowerment & Compassion</h3>
              <p className="text-gray-600">Empowering individuals to take control of their lives and make meaningful changes.
              . Approaching coaching with empathy and understanding, ensuring a supportive and nurturing environment for all clients.</p>
            </div>
            <div className="p-4">
              <FontAwesomeIcon icon={faChartLine} className="text-blue-600 text-4xl mx-auto mb-4"/>
              <h3 className="text-xl font-semibold mb-2">Growth</h3>
              <p className="text-gray-600"> Fostering continuous personal and professional development for clients and the organization.</p>
            </div>
            <div className="p-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-blue-600 text-4xl mx-auto mb-4"/>
              <h3 className="text-xl font-semibold mb-2">Integrity</h3>
              <p className="text-gray-600">Maintaining honesty and transparency in all interactions and commitments.
              </p>
            </div>
          </div>
        </section>

        {/* Our Company Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4 text-center">Our Company</h2>
          <p className="text-gray-600 mb-12 text-center">When you need us to improve your business, then come with us to help your team reach it. You just sit and feel that goal.</p>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <img src={companyImg} alt="Our Company" className="w-full md:w-1/2 rounded-lg shadow-md mb-8 md:mb-0 md:mr-8"/>
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">How we work with our Clients</h3>
              <p className="text-gray-600 mb-4">Providing exceptional experiences, ensuring every interaction leaves a lasting impression. We prioritize your needs and work together to achieve success.</p>
              <Link to="/contact">
                <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300">
                  Contact Us
                </button>
              </Link>    
              </div>        
          </div>
        </section>

        {/* Dream Section */}
        <section className="mb-16">
          <div className="relative w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
              <button
                onClick={() => document.getElementById('about-video').play()}
                className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 text-lg font-bold"
              >
                Watch Video
              </button>
            </div>
            
          </div>
          <div className="mt-8 text-center">
            <h2 className="text-3xl font-bold mb-4">Dream Until Your Dreams Come True</h2>
            <p className="text-lg mb-4">
            Our consultants help you overcome your most challenging issues by providing expert life coaching services focused on personal growth, goal-setting, and achieving work-life balance. We offer tailored support to guide you through transitions, enhance your well-being, and empower you to reach your full potential.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
