import React from 'react';
import profilePic from '../assets/images/coachImage.jpg'; // adjust the path as needed

const DetailedProfile = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="container mx-auto bg-white p-8 rounded-lg shadow-md flex flex-col md:flex-row items-center md:items-start">
        <div className="w-full md:w-2/5 flex justify-center">
          <img
            src={profilePic}
            alt="Mohamed Mursal"
            className="w-full h-auto max-w-sm rounded-full mb-6 md:mb-0"
          />
        </div>
        <div className="w-full md:w-3/5">
          <h1 className="text-4xl font-bold mb-4">About Mohamed Mursal</h1>
          <p className="text-gray-700 mb-4">
            Hello, I'm Mohamed Mursal, a life coach, author, and entrepreneur. I've written two books and have dedicated my career to helping people gain clarity on their goals, sustain them, and achieve work-life balance. As the founder of Murzali Platform, I run the Empowerment Edition newsletter and the Exclusive Journaling Club. With clients from over ten countries, I'm passionate about guiding individuals to reach their full potential.
          </p>
          <p className="text-gray-700 mb-4">
            My journey into life coaching began with my own struggles to find a balance between work and personal life. I realized that many people face similar challenges, and I wanted to help them overcome these obstacles. Through my coaching, I've helped countless individuals achieve their goals, improve their productivity, and lead more fulfilling lives.
          </p>
          <p className="text-gray-700 mb-4">
            In addition to my coaching work, I'm also an author of two books that focus on personal development and achieving success. These books have been well-received by readers worldwide and have helped many people gain a better understanding of themselves and their potential.
          </p>
          <p className="text-gray-700 mb-4">
            At Murzali Platform, we offer a range of services designed to help you succeed. Whether you're looking for one-on-one coaching, joining our Exclusive Journaling Club, or subscribing to our Empowerment Edition newsletter, we have something for everyone. Our goal is to empower you to achieve your dreams and live a balanced, successful life.
          </p>
          <p className="text-gray-700 mb-4">
            I believe that everyone has the potential to achieve greatness, and my mission is to help you unlock that potential. With over 4 years of experience in the field, I have the knowledge and expertise to guide you on your journey to success.
          </p>
          <p className="text-gray-700 mb-4">
            Thank you for taking the time to learn more about me. I look forward to working with you and helping you achieve your goals.
          </p>
          <button
            onClick={() => window.history.back()}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 text-lg font-semibold"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailedProfile;
