import React from 'react';
import { Link } from 'react-router-dom';
import contact from '../assets/images/contact.svg';

const Contact = () => {
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Contact Us</title>
      <link rel="stylesheet" href="https://unicons.iconscout.com/release/v2.1.6/css/unicons.css" />
      <link rel="stylesheet" href="https://iconscout.com/unicons/explore/line" />
      <link rel="stylesheet" href="https://unpkg.com/swiper@7/swiper-bundle.min.css" />

      <section className="contact my-12">
        <div className="container mx-auto bg-blue-100 p-8 md:p-16 rounded-lg grid grid-cols-1 md:grid-cols-2 gap-16 relative z-10">
          <aside className="contact__aside bg-blue-900 text-white p-12 rounded-lg relative md:-bottom-20">
            <div className="aside__image mb-8">
              <img src={contact} alt="Contact Us" className="w-48 mx-auto" />
            </div>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="text-sm mb-8">Get in touch with us for any inquiries or support.</p>
            <ul className="contact__details space-y-4 mb-8 text-sm md:text-base">
              <li className="flex items-center">
                <i className="uil uil-phone-times text-xl mr-2" />
                <h5 className="text-lg">+252614035267</h5>
              </li>
              <li className="flex items-center ">
                <i className="uil uil-envelope text-xl mr-2" />
                <h5 className="text-lg break-words max-w-xs">Murzaliplatform@gmail.com</h5>
              </li>
              <li className="flex items-center">
                <i className="uil uil-location-point text-xl mr-2" />
                <h5 className="text-lg">Mogadishu, Somalia</h5>
              </li>
            </ul>
            <ul className="contact__socials flex space-x-4">
              <li><a href="https://facebook.com" className="bg-gray-300 p-2 rounded-full text-xl hover:bg-transparent transition"><i className="uil uil-facebook-f" /></a></li>
              <li><a href="https://instagram.com" className="bg-gray-300 p-2 rounded-full text-xl hover:bg-transparent transition"><i className="uil uil-instagram" /></a></li>
              <li><a href="https://twitter.com" className="bg-gray-300 p-2 rounded-full text-xl hover:bg-transparent transition"><i className="uil uil-twitter" /></a></li>
              <li><a href="https://linkedin.com" className="bg-gray-300 p-2 rounded-full text-xl hover:bg-transparent transition"><i className="uil uil-linkedin-alt" /></a></li>
            </ul>
          </aside>
          <form action="https://formspree.io/f/xnqraagv" method="POST" className="contact__form flex flex-col gap-4">
            <div className="form__name flex gap-4">
              <input type="text" name="First Name" placeholder="First Name" required className="w-1/2 p-3 border border-gray-300 rounded bg-gray-800 text-white" />
              <input type="text" name="Last Name" placeholder="Last Name" required className="w-1/2 p-3 border border-gray-300 rounded bg-gray-800 text-white" />
            </div>
            <input type="email" name="Email Address" placeholder="Your Email Address" required className="w-full p-3 border border-gray-300 rounded bg-gray-800 text-white" />
            <textarea name="Message" rows={7} placeholder="Message" required className="w-full p-3 border border-gray-300 rounded bg-gray-800 text-white" />
            <button type="submit" className="btn bg-blue-900 text-white py-3 px-6 rounded self-start hover:bg-blue-800 transition duration-300">Send Message</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
